import React, { Key, useState } from 'react'

import { Button, Table, Modal } from 'antd'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { arrayUtil, displayUtil, filterUtil } from '../util';
import { FormattedMessage, useIntl } from 'react-intl';
import { DeliveryNoteDto, DeliveryOutputSearchDto } from './api'
import { Field, useFields } from '../fields';
import { constListScroll, DateRange, Filter, InputFilter, RangeFilter, SelectFilter } from '../common'
import { SearchOutlined, RollbackOutlined } from '@ant-design/icons';
import { SorterResult } from 'antd/lib/table/interface';

export interface Props {
    visible: boolean;
    data?: DeliveryNoteDto[];
    keys: DeliveryNoteDto[];
    handleSetKeys: (keys: DeliveryNoteDto[]) => void;
    locationCdOptions: Field[];
    openFormModal: () => void;
    openSearchForm: () => void;
    handleSelectedRows: (selectedRows: DeliveryNoteDto[]) => void;
    searchDto: DeliveryOutputSearchDto;
    handleResearch: (searchFrom: DeliveryOutputSearchDto) => void;
    reset: (rowCount: number) => void;
}

export function DeliveryOutputList(props: Props) {

    const intl = useIntl();
    const fullSupplierOptions = useFields('fullSupplier')
    const [rowCount, setRowCount] = useState<number>(10);
    type filterType = Filter<DeliveryNoteDto>;
    const itemOptions = useFields('item');
    const locationNames = useFields('locationName');

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: DeliveryNoteDto[]) => {

            props.handleSetKeys(selectedRows);
            // 交付日期，到货日期相同出货

            checkRows(selectedRows);
        }
    };

    const popError = () => {
        Modal.error({
            title: intl.formatMessage({ id: 'common.modalTitle' }),
            content: intl.formatMessage({ id: 'message.checkLocation' }),
            okText: intl.formatMessage({ id: 'common.close' })
        });
    }

    const popOrderNoError = () => {
        Modal.error({
            title: intl.formatMessage({ id: 'common.modalTitle' }),
            content: intl.formatMessage({ id: 'message.commonElementError' }, { a: intl.formatMessage({ id: 'delivery.orderNo' }) }),
            okText: intl.formatMessage({ id: 'common.close' })
        });
    }

    const delivery = () => {
        if (checkRows(props.keys)) {
            props.handleSelectedRows(props.keys);
            props.openFormModal();
        }
        if (props.keys.length === 0) {
            popNonError();
        }
    }

    const checkRows = (selectedRows: DeliveryNoteDto[]) => {

        if (!selectedRows || selectedRows.length === 0) {

            return false;
        }

        if (commonlocationNameAndTimeArriva(selectedRows)) {
            popError();
            return false;
        } else if (commonData(selectedRows.filter(i => i.orderNo))) {
            popOrderNoError();
            return false;
        }

        return true;
    }

    function checkDuplicate<T>(values: T[], getKey: (a: T) => string, result: boolean) {

        if (values.length === 0 || values.length === 1) {

            return false;
        }

        const set = new Set();
        for (let value of values) {
            const key = getKey(value);

            if (set.has(key) && result) {
                return result;
            }

            if (!set.has(key) && set.size >= 1 && !result) {
                return !result;
            }

            set.add(key);
        }

        return false;
    }

    const commonData = (array: any[]): boolean => {

        return checkDuplicate(array, (a: any) => (`${a.orderNo}_${a.detailNo}`), true);
    }

    const commonlocationNameAndTimeArriva = (array: DeliveryNoteDto[]): boolean => {

        return checkDuplicate(array, (a: DeliveryNoteDto) => (`${a.locationName}_${a.estimatedTimeArriva.startOf('day').valueOf()}`), false);
    }

    const handleTableChange = (pagination: TablePaginationConfig,
        filters: Record<string, ( Key | boolean)[] | null>,
        sorter: SorterResult<DeliveryNoteDto> | SorterResult<DeliveryNoteDto>[],
        extra: any) => {

        const { locationName: preLocationName } = props.searchDto;
        const { locationName } = filters;
        const preLocationNames = preLocationName ? [preLocationName] : [];
        const curentLocationNames = locationName ? locationName as string[] : [];


        if (!!pagination.pageSize && rowCount !== pagination.pageSize) {
            setRowCount(pagination.pageSize)
        }

        if (!arrayUtil.equals(curentLocationNames, preLocationNames)) {
            props.searchDto.locationName = curentLocationNames[0];
            props.handleResearch(props.searchDto);
        }

        let researchFlag = false;
        const researchDto: DeliveryOutputSearchDto = { ...props.searchDto };

        if (!!pagination.pageSize && props.searchDto.rowCount !== pagination.pageSize) {
            researchDto.page = 1;
            researchDto.rowCount = pagination.pageSize;
            researchFlag = true;
        }
        else if (props.searchDto.page !== pagination.current) {
            researchDto.page = pagination.current;
            researchFlag = true;
        }

        const fixSorter = sorter as SorterResult<DeliveryNoteDto>;
        if (props.searchDto.sortField !== fixSorter.field
            || props.searchDto.sortOrder !== fixSorter.order) {
            researchDto.sortField = fixSorter.field as string | undefined;
            researchDto.sortOrder = fixSorter.order;
            researchFlag = true;
        }
        
        if (researchFlag) {
            props.handleResearch(researchDto);
        }

    }

    const popNonError = () => {
        Modal.error({
            title: intl.formatMessage({ id: 'common.modalTitle' }),
            content: intl.formatMessage({ id: 'estimate.checkSelect' }),
            okText: intl.formatMessage({ id: 'common.close' })
        });
    }
    const filterSelect = (itemTitle: 'item' | 'suppliers' | 'locationCd' ) =>
        (value: string[]) => {
            if (props.searchDto[itemTitle] !== value) {
                const researchDto: DeliveryOutputSearchDto = { ...props.searchDto };
                researchDto[itemTitle] = value;
                researchDto.page = 1;
                props.handleResearch(researchDto);
            }
        };

    const filterRanger = (itemTitle1: 'estimatedTimeArriva') =>
        (value: DateRange) => {
            if (props.searchDto[itemTitle1] !== value) {
                const researchDto: DeliveryOutputSearchDto = { ...props.searchDto };
                researchDto[itemTitle1] = value;
                researchDto.page = 1;
                props.handleResearch(researchDto);
            }
        };

    const filterInput = (itemTitle1: 'orderNo' | 'itemName') =>
        (value?: string) => {
            if (props.searchDto[itemTitle1] !== value) {
                const researchDto: DeliveryOutputSearchDto = { ...props.searchDto };
                researchDto[itemTitle1] = value;
                researchDto.page = 1;
                props.handleResearch(researchDto);
            }
        };

    const sortOrder = (itemTitle1: string): 'ascend' | 'descend' | null | undefined => {

        if (itemTitle1 === props.searchDto.sortField) {
            return props.searchDto.sortOrder;
        }

        return null;
    };

    const suppliersFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <SelectFilter fields={fullSupplierOptions} values={props.searchDto.suppliers} onChange={filterSelect('suppliers')} />,
        filteredValue: props.searchDto.suppliers,
    };

    const itemFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <SelectFilter fields={itemOptions} values={props.searchDto.item} onChange={filterSelect('item')} />,
        filteredValue: props.searchDto.item,
    };

    const estimatedTimeArrivaFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <RangeFilter values={props.searchDto.estimatedTimeArriva} onChange={filterRanger('estimatedTimeArriva')} />,
        filteredValue: filterUtil.range2stringArray(props.searchDto.estimatedTimeArriva),
    };

    const orderNoFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <InputFilter value={props.searchDto.orderNo} onChange={filterInput('orderNo')} />,
        filteredValue: !props.searchDto.orderNo ? [] : [props.searchDto.orderNo],
    };
    const itemNameFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <InputFilter value={props.searchDto.itemName} onChange={filterInput('itemName')} />,
        filteredValue: !props.searchDto.itemName ? [] : [props.searchDto.itemName],
    };

    const LocationNameFilter: filterType = {
        filters: filterUtil.field2Fileter(locationNames), filterMultiple: false,
        filteredValue: !props.searchDto.locationName ? [] : [props.searchDto.locationName],
        onFilter: (value: any, record: DeliveryNoteDto) => record.locationName === value 
    };

    // 作成日
    const dataCreationDateTitle = <FormattedMessage id='delivery.dataCreationDate' />;
    //保管場所
    const supplierTitle = <FormattedMessage id='delivery.supplier' />;
    const supplierNameTitle = <FormattedMessage id='delivery.supplierName' />;
    const locationTitle = <FormattedMessage id='delivery.locationCd' />;
    //着荷予定日
    const estimatedTimeArrivaTitle = <FormattedMessage id='delivery.estimatedTimeArriva' />;
    //発注者
    const buyerTitle = <FormattedMessage id='delivery.buyer' />;
    //発注者名称
    const buyerNameTitle = <FormattedMessage id='delivery.buyerName' />;
    // 品目コード
    const itemTitle = <FormattedMessage id='delivery.item' />;
    // 品目名称
    const itemNameTitle = <FormattedMessage id='delivery.itemName' />;
    // // 納品数量
    // const deliveryQuantityTitle = <FormattedMessage id='orderDetail.deliveryQuantity' />;
    // // 注文数量
    // const orderQantityTitle = <FormattedMessage id='orderDetail.quantity' />;
    // 納品計画数量
    const planDeliveryQuantityTitle = <FormattedMessage id='orderResponse.planDeliveryQuantity' />;
    // 単価
    const unitPriceTitle = <FormattedMessage id='delivery.unitPrice' />;
    // 数量単位
    const unitTitle = <FormattedMessage id='orderDetail.unit' />;
    // 通貨
    const currencyCodeTitle = <FormattedMessage id='delivery.currencyCode' />;
    // 注文番号
    const orderNoTitle = <FormattedMessage id='delivery.orderNo' />;
    const width270 = '270px';
    const width240 = '240px';
    const width200 = '200px'
    const width150 = '150px';
    const width140 = '140px';
    const width160 = '160px';
    const width120 = '120px';
    const width100 = '100px';
    const width80 = '80px';
    const width60 = '60px';

    const showUnitPrice = (text: any, record: DeliveryNoteDto, index?: number) => {
        return displayUtil.currency(record.currencyCode, record.unitPrice)
    };
    const showOrderNo = (text: any, record: DeliveryNoteDto, index?: number) => {
        return displayUtil.concatOrderNo(record.orderNo, record.detailNo)
    };


    const columns: ColumnsType<DeliveryNoteDto> = [
        // 复选框
        // TODO:点击复选框，完成和后台交互
        // 受注者
        { title: supplierTitle, dataIndex: 'supplier', width: width120, ellipsis: true, sorter: true, sortOrder: sortOrder('supplier'), ...suppliersFilter },
        // 受注者名称
        { title: supplierNameTitle, dataIndex: 'supplierName', width: width140, ellipsis: true },
        // 保管場所
        { title: locationTitle, dataIndex: 'locationName', render: displayUtil.field(props.locationCdOptions), width: width140, ellipsis: true, sorter: true, sortOrder: sortOrder('locationName'), ...LocationNameFilter },
        // 着荷予定日
        { title: estimatedTimeArrivaTitle, dataIndex: 'estimatedTimeArriva', render: displayUtil.date, width: width160, ...estimatedTimeArrivaFilter, sorter: true, sortOrder: sortOrder('estimatedTimeArriva') },
        // 品目コード
        { title: itemTitle, dataIndex: 'item', width: width140, ...itemFilter, sorter: true, sortOrder: sortOrder('item') },
        // 品目名称
        { title: itemNameTitle, dataIndex: 'itemName', width: width160, ellipsis: true, ...itemNameFilter, sorter: true, sortOrder: sortOrder('itemName') },
        // 注文番号
        { title: orderNoTitle, dataIndex: 'orderNo', width: width140, render: showOrderNo, ...orderNoFilter, sorter: true, sortOrder: sortOrder('orderNo') },
        // // 注文数量
        // {title: orderQantityTitle, dataIndex: 'quantity',width: width80},
        // // 出荷済数量
        // {title: deliveryQuantityTitle, dataIndex: 'deliveryQuantity',width: width80},
        // 出荷予定数量
        { title: planDeliveryQuantityTitle, dataIndex: 'planDeliveryQuantity', width: width140 },
        // 単位
        { title: unitTitle, dataIndex: 'unit', width: width60 },
        // 単価
        { title: unitPriceTitle, dataIndex: 'unitPrice', width: width160, render: showUnitPrice },
        // 通貨
        { title: currencyCodeTitle, dataIndex: 'currencyCode', width: width60, },
        // //発注者
        // { title: buyerTitle, dataIndex: 'buyer', width: width100 },
        // 発注者名称
        { title: buyerNameTitle, dataIndex: 'buyerName', width: width200, ellipsis: true },
        // // 作成日
        // {title: dataCreationDateTitle, dataIndex: 'dataCreationDate', render: displayUtil.date,width: width100}
    ];

    const title = () => (<>
        <FormattedMessage id='delivery.unprocessedlist' />
        <Button onClick={props.openSearchForm} style={{ marginLeft: 10, marginRight: 10 }} >
            <SearchOutlined />
            <FormattedMessage id='shipment.search' />
        </Button>
        <Button onClick={delivery} style={{ marginLeft: 10, marginRight: 10 }} >
            <FormattedMessage id='delivery.output' />
        </Button>
        <Button className='reset-btn' onClick={() => { props.reset(rowCount); }}>
            <RollbackOutlined />
            <FormattedMessage id='common.reset' />
        </Button>
    </>)

    return (
        <>
            <Table<DeliveryNoteDto>
                className="list-table"
                pagination={false}
                columns={columns}
                dataSource={props.data}
                rowClassName={displayUtil.rowClassName}
                rowKey={'id'}
                tableLayout={'fixed'}
                title={title}
                rowSelection={rowSelection}
                onChange={handleTableChange}
                scroll={constListScroll}
            />
        </>
    )
}
