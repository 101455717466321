import React, { useEffect, useState } from 'react'
import { DetailStates } from '../common';

// 機能内部のAPIs
import { DeliveryNoteDto, getUnprocessedDeliveryDetails, OutputForm, persist, OutPutFormCondition, checkCountDeliveryNo, DeliveryOutputSearchDto, outputDto2search, deliveryOutputSearch2Dto } from './api';
import { Props as ListProps } from './DeliveryOutputList';
import { Props as FormProps } from './DeliveryOuputForm';
import { Props as SearchProps } from './DeliveryOutputSearchForm';
import { useFields } from '../fields';
import { Modal } from 'antd';
import { useIntl } from 'react-intl';
import { useHistory, useLocation, useParams } from 'react-router-dom';

/**
 * 納品出力のコンポネート
 */
export function DeliveryOutputHoc(
    List: React.ComponentType<ListProps>,
    Form: React.ComponentType<FormProps>,
    Search: React.ComponentType<SearchProps>) {

    // 納品書出力一览
    const [listData, setListData] = useState<DetailStates<DeliveryNoteDto[]>>({ visible: true });
    const [keys, setKeys] = useState<DeliveryNoteDto[]>([]);
    const [formVisible, setFormVisible] = useState<boolean>(false);
    const [searchFormVisible, setSearchFormVisible] = useState<boolean>(false);
    const [outputForm, setOutputForm] = useState<OutputForm[]>([])
    const locationCds = useFields('locationName');
    const { search } = useLocation();
    const [searchDto, setSearchDto] = useState<DeliveryOutputSearchDto>(deliveryOutputSearch2Dto(search));
    const { push } = useHistory();
    const intl = useIntl();
    // URLのID
    useEffect(() => {
        setSearchFormVisible(false);
        setSearchDto(deliveryOutputSearch2Dto(search));
        getUnprocessedDeliveryDetails(search)
            .then((record: DeliveryNoteDto[]) => {
                setListData({ visible: true, data: record })
            })
            .catch(() => {

            });

    }, [search])

    const handleSelectedRows = (selectedRows: DeliveryNoteDto[]) => {
        let formatSelectedRow: OutputForm[] = [];
        selectedRows.forEach((item) => {
            formatSelectedRow.push({
                id: item.id,
                version: item.version,
                orderNo: item.orderNo + item.detailNo
            })
        });
        setOutputForm(formatSelectedRow)
    }

    const handleOutput = (outPut: OutPutFormCondition) => {

        if (outPut.deliveryReportType === '001' && outputForm.length > 1) { // EIAJ納品書の場合、件数が1より大きくなることはありません

            Modal.error({
                title: intl.formatMessage({ id: 'common.modalTitle' }),
                content: intl.formatMessage({ id: 'message.outputDataLinesError' }),
                okText: intl.formatMessage({ id: 'common.close' })
            });
        } else {
            const fixedDeliveryNo = outPut.deliveryNo || ''
            checkCountDeliveryNo(fixedDeliveryNo).then((result: boolean) => {
                if (result) {

                    Modal.error({
                        title: intl.formatMessage({ id: 'common.modalTitle' }),
                        content: intl.formatMessage({ id: 'message.deliveryNoUnique' }),
                        okText: intl.formatMessage({ id: 'common.close' })
                    });

                } else {
                    persist(outputForm, outPut)
                        .then((result: any) => {
                            setFormVisible(false)
                            getUnprocessedDeliveryDetails()
                                .then((record: DeliveryNoteDto[]) => {
                                    setListData({ visible: true, data: record })
                                    setKeys([])
                                })
                                .catch(() => {

                                });
                        })
                }
            })
        }
    }

    const handleSearch = (researchDto: DeliveryOutputSearchDto) => {

        const query = outputDto2search(researchDto);

        // URLを再設定する
        push(`/deliveryoutput${query}`);
    }


    const reset = (rowCount: number) => {
        handleSearch({
            suppliers: [],
            locationCd: [],
            locationName: '',
            estimatedTimeArriva: [null,null],
            item: [],
            itemName: '',
            orderNo: '',
            rowCount
        }  as DeliveryOutputSearchDto)
    }

    // 出力一览
    const listProps = {
        // 出力画面の表示状態
        visible: listData.visible,
        // 出力
        data: listData.data,
        keys: keys,
        handleSetKeys: (keys: DeliveryNoteDto[]) => setKeys(keys),
        searchDto: searchDto,
        locationCdOptions: locationCds,
        openFormModal: () => setFormVisible(true),
        openSearchForm: () => setSearchFormVisible(true),
        // selectedRows: selectedRows,
        handleSelectedRows: handleSelectedRows,
        handleResearch: handleSearch,
        reset
    };

    const formProps = {
        visible: formVisible,
        close: () => setFormVisible(false),
        handleOutput: handleOutput,
    }

    const searchProps = {
        visible:  searchFormVisible,
        close: () => setSearchFormVisible(false),
        searchDto: searchDto,
        handleSearch: handleSearch
    }

    return (
        <>
            <List {...listProps} />
            <Form {...formProps} />
            <Search {...searchProps} />
        </>
    )
}