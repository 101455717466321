import { DeleteOutlined, FileExcelOutlined, RollbackOutlined, SearchOutlined, DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Table, TablePaginationConfig, Popconfirm, Row, Col } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import React, { Key, useState } from 'react'
import { FormattedMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { constListScroll, constPageSizeOptions, DateRange, Filter, InputFilter, RangeFilter, SelectFilter } from '../common';
import { useFields } from '../fields';
import { arrayUtil, displayUtil, filterUtil } from '../util';
import { InventoryDto, InventorySearchDto } from './api'
import { useUser } from '../authorization';

export interface Props {
    loading: boolean;
    total: number;
    data: InventoryDto[];
    searchDto: InventorySearchDto;
    openSearchModal: () => void;
    openFormModal: () => void;
    handleDelete: (id: number, version: number) => void;
    handleResearch: (researchDto: InventorySearchDto) => void;
    handleSearch: (searchDto: InventorySearchDto) => void;
    download: () => void;
    reset:(rowCount:number)=> void;
}

export function InventoryList(props: Props) {

    const { push } = useHistory();

    const user = useUser();
    const [rowCount, setRowCount] = useState<number>(10);
    const primaryLenders = useFields('supplier');
    const fullSupplierOptions = useFields('fullSupplier');

    const moldStatus = useFields('moldStatus');

    // ページング、フィルター、ソート順の処理
    const handleTableChange = (pagination: TablePaginationConfig,
        filters: Record<string, (Key | boolean)[] | null>,
        sorter: SorterResult<InventoryDto> | SorterResult<InventoryDto>[],
        extra: any) => {

        // 再検索の条件を作成する
        const researchDto: InventorySearchDto = { ...props.searchDto };
        let researchFlag = false;

        //案件状態の場合
        const { status: preStatus } = researchDto;
        const { status } = filters;
        const curentStatus = status ? status as string[] : [];

        if (!!pagination.pageSize && rowCount !== pagination.pageSize) {
            setRowCount(pagination.pageSize)
        }

        if (!arrayUtil.equals(curentStatus, preStatus)) {
            researchDto.status = curentStatus;
            props.handleSearch(researchDto);
        }

        if (!!pagination.pageSize && props.searchDto.rowCount !== pagination.pageSize) {
            researchDto.page = 1;
            researchDto.rowCount = pagination.pageSize;
            researchFlag = true;
        }
        else if (props.searchDto.page !== pagination.current) {
            researchDto.page = pagination.current;
            researchFlag = true;
        }

        const fixSorter = sorter as SorterResult<InventoryDto>;
        if (props.searchDto.sortField !== fixSorter.field
            || props.searchDto.sortOrder !== fixSorter.order) {
            researchDto.sortField = fixSorter.field as string | undefined;
            researchDto.sortOrder = fixSorter.order;
            researchFlag = true;
        }

        if (researchFlag) {
            props.handleResearch(researchDto);
        }
    };
    const filterSelect = (itemTitle: 'primaryLenders' | 'status') =>
        (value: string[]) => {
            if (props.searchDto[itemTitle] !== value) {
                const researchDto: InventorySearchDto = { ...props.searchDto };
                researchDto[itemTitle] = value;
                researchDto.page = 1;
                props.handleResearch(researchDto);
            }
        };

    const filterInput = (itemTitle: 'inventoryRequestNo' | 'primaryLenderName') =>
        (value?: string) => {
            if (props.searchDto[itemTitle] !== value) {
                const researchDto: InventorySearchDto = { ...props.searchDto };
                researchDto[itemTitle] = value;
                researchDto.page = 1;
                props.handleResearch(researchDto);
            }
        };
    const filterRanger = (itemTitle: 'requestDate' | 'answerDeadline') =>
        (value: DateRange) => {
            if (props.searchDto[itemTitle] !== value) {
                const researchDto: InventorySearchDto = { ...props.searchDto };
                researchDto[itemTitle] = value;
                researchDto.page = 1;
                props.handleResearch(researchDto);
            }
        };

    const sortOrder = (itemTitle: string): 'ascend' | 'descend' | null | undefined => {

        if (itemTitle === props.searchDto.sortField) {
            return props.searchDto.sortOrder;
        }
        return null;
    };

    //filter
    type filterType = Filter<InventoryDto>;

    const inventoryRequestNoFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <InputFilter value={props.searchDto.inventoryRequestNo} onChange={filterInput('inventoryRequestNo')} />,
        filteredValue: !props.searchDto.inventoryRequestNo ? [] : [props.searchDto.inventoryRequestNo],
    };
    const primaryLenderFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <SelectFilter fields={fullSupplierOptions} values={props.searchDto.primaryLenders} onChange={filterSelect('primaryLenders')} />,
        filteredValue: props.searchDto.primaryLenders,
    };
    const primaryLenderNameFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <InputFilter value={props.searchDto.primaryLenderName} onChange={filterInput('primaryLenderName')} />,
        filteredValue: !props.searchDto.primaryLenderName ? [] : [props.searchDto.primaryLenderName],
    };
    const requestDateFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <RangeFilter values={props.searchDto.requestDate} onChange={filterRanger('requestDate')} />,
        filteredValue: filterUtil.range2stringArray(props.searchDto.requestDate),
    };
    const answerDeadlineFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <RangeFilter values={props.searchDto.answerDeadline} onChange={filterRanger('answerDeadline')} />,
        filteredValue: filterUtil.range2stringArray(props.searchDto.answerDeadline),
    };

    const statusFilter: filterType = {
        filters: filterUtil.field2Fileter(moldStatus), filterMultiple: true,
        filteredValue: props.searchDto.status,
        onFilter: (value: any, record: InventoryDto) => record.status === value
    };

    const inventoryRequestNoLink = (text: any, record: InventoryDto, index: number) => {
        return <Link to={`/modelassets/${record.id}`}>{record.inventoryRequestNo}</Link>;
    };

    // タイトル
    // 回答状況
    const statusTitle = <FormattedMessage id='inventory.status' />
    // 有高確認依頼番号
    const inventoryRequestNoTitle = <FormattedMessage id='inventory.inventoryRequestNo' />
    // 一次預け先コード
    const primaryLenderTitle = <FormattedMessage id='model.primaryLender' />
    // 一次預け先名称
    const primaryLenderNameTitle = <FormattedMessage id='model.primaryLenderName' />
    // 回答希望日
    const answerDeadlineTitle = <FormattedMessage id='inventory.answerDeadline' />
    // 依頼日
    const requestDateTitle = <FormattedMessage id='inventory.requestDate' />
    // 金型数
    const modelNumTitle = <FormattedMessage id='inventory.modelNum' />

    const width260 = '260px';
    const width200 = '200px';
    const width180 = '180px';
    const width160 = '160px';
    const width130 = '130px';
    const width120 = '120px';
    const width150 = '150px';
    const width80 = '80px';

    const delBtn = (id: number, ver: number) => {
        return (
            <>
                <Popconfirm placement="left" title={<FormattedMessage id='message.delConfirm' />}
                    onConfirm={(event) => props.handleDelete(id, ver)}
                    okText={<FormattedMessage id='common.delete' />}
                    cancelText={<FormattedMessage id='common.cancel' />} >
                    <Button className="deleteBtn">
                        <DeleteOutlined />
                    </Button>
                </Popconfirm>
            </>
        )
    };
    const columns: ColumnsType<InventoryDto> = [

        // 回答状況
        {
            title: statusTitle, width: width160,
            dataIndex: 'status',
            sorter: true, sortOrder: sortOrder('status'),
            render: displayUtil.field(moldStatus),
            ...statusFilter,
        },
        // 有高確認依頼番号
        {
            title: inventoryRequestNoTitle, width: width200,
            dataIndex: 'inventoryRequestNo',
            sorter: true, sortOrder: sortOrder('inventoryRequestNo'),
            ...inventoryRequestNoFilter,
            render: inventoryRequestNoLink,
        },
        // 一次預け先コード
        {
            title: primaryLenderTitle, width: width160,
            dataIndex: 'primaryLender',
            sorter: true, sortOrder: sortOrder('primaryLender'),
            ...primaryLenderFilter,
        },
        // // 一次預け先名称
        {
            title: primaryLenderNameTitle, width: width260,
            dataIndex: 'primaryLender',
            sorter: true, sortOrder: sortOrder('primaryLender'),
            ...primaryLenderNameFilter,
            render: displayUtil.field(primaryLenders),
            ellipsis: true
        },

        // 回答希望日
        {
            title: answerDeadlineTitle, width: width160,
            dataIndex: 'answerDeadline',
            sorter: true, sortOrder: sortOrder('answerDeadline'),
            ...answerDeadlineFilter,
            render: displayUtil.date
        },
        // 依頼日
        {
            title: requestDateTitle, width: width200,
            dataIndex: 'requestDate',
            sorter: true, sortOrder: sortOrder('requestDate'),
            ...requestDateFilter,
            render: displayUtil.date
        },
        // 金型数
        {
            title: modelNumTitle, width: width120,
            dataIndex: 'modelNum'
        },
    ];
    if (!user?.isSupplier) {
        columns.push({ title: '', width: width80, dataIndex: 'id', render: (text: any, record: InventoryDto, index: number) => delBtn(record.id, record.version) });
    }

    const paginationProps: TablePaginationConfig = {
        current: props.searchDto.page,
        total: props.total,
        defaultPageSize: props.searchDto.rowCount,
        position: ['bottomLeft'],
        showSizeChanger: true,
        pageSizeOptions: constPageSizeOptions,
    };
    const title = () => {
        return (
            <>
                <FormattedMessage id='inventory.list' />
                <Button onClick={props.openSearchModal} style={{ marginLeft: 10, marginRight: 10 }} >
                    <SearchOutlined />
                    <FormattedMessage id='common.conditions' />
                </Button>
                <Button onClick={props.download} style={{ marginLeft: 10, marginRight: 10 }} >
                    <DownloadOutlined />
                    <FormattedMessage id='common.download' />
                </Button>
                {!user?.isSupplier &&
                    <Button onClick={props.openFormModal} style={{ marginLeft: 10, marginRight: 10 }} >
                        <PlusOutlined />
                        <FormattedMessage id='inventory.inventoryRequest' />
                    </Button>
                }
                <Button className='reset-btn' onClick={() => { props.reset(rowCount); }}>
                    <RollbackOutlined />
                    <FormattedMessage id='common.reset' />
                </Button>

            </>
        )
    };
    return (
        <Table<InventoryDto>
            className="list-table"
            dataSource={props.data}
            columns={columns}
            pagination={paginationProps}
            loading={props.loading}
            rowClassName={displayUtil.rowClassName}
            rowKey={'id'}
            tableLayout={'fixed'}
            title={title}
            onChange={handleTableChange}
            scroll={constListScroll}
        // rowSelection={rowSelection}        
        />
    );
}