import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import * as XLSX from 'xlsx';

import { Props as ListProps } from './InventoryList';
import { Props as SearchProps } from './InventorySearch';
import { Props as DetailProps } from './InventoryDetail';
import { Props as SupplierDetailProps } from './SupplierInventoryDetail';
import { Props as InventoryEntryProps } from './InventoryEntry';
import { Props as InventoryEntryConfrimProps } from './InventoryEntryConfrim';

import { DetailStates, ListStates } from '../common';
import { dto2search, get, getById, InventoryDto, InventorySearchDto, removeById, search2Dto, mergeInventory, ModelRequestDto, getModelNumBySupplier, ModelNumBySupplierDto, persist, InventoryDetailDto, reply } from './api';
import dayjs from 'dayjs';
import { displayUtil } from '../util';
import { useFields } from '../fields';
import { useUser } from '../authorization';

export function InventoryHoc(
    List: React.ComponentType<ListProps>,
    Search: React.ComponentType<SearchProps>,
    Detail: React.ComponentType<DetailProps>,
    SupplierDetial: React.ComponentType<SupplierDetailProps>,
    EntryProps: React.ComponentType<InventoryEntryProps>,
    EntryConfrimProps: React.ComponentType<InventoryEntryConfrimProps>
) {
    // 共通のstates
    const intl = useIntl();
    const user = useUser();


    // URLのID
    const { id: urlId } = useParams<{ id?: string }>();
    // URLのquery文字列
    const { search } = useLocation();
    // URL変更のハンドル
    const history = useHistory();
    const { push, goBack } = useHistory();
    // 検索条件（フィルター、ページとソート順）
    const [searchDto, setSearchDto] = useState<InventorySearchDto>(search2Dto(search));
    // // 検索条件（フィルター、ページとソート順）
    // const [ModelRequestDto, setModelRequestDto] = useState<InventorySearchDto>();

    // 一覧のstates
    const [listData, setListData] = useState<ListStates<InventoryDto>>({ loading: false, total: 0, data: [] });

    const [resultData, setResultData] = useState<ListStates<ModelNumBySupplierDto>>({ loading: false, total: 0, data: [] });

    // Flag
    const [refresh, setRefresh] = useState<boolean>(false);

    // 検索のstates
    // 検査画面の表示状態
    const [searchVisible, setSearchVisible] = useState<boolean>(false);

    // 詳細のstates
    const [detailData, setDetailData] = useState<DetailStates<InventoryDto>>({ visible: false });

    const [formVisible, setFormVisible] = useState<boolean>(false);

    const [entryConfrimVisible, setEntryConfrimVisible] = useState<boolean>(false);

    const [requestData, setRequestData] = useState<DetailStates<ModelRequestDto>>({ visible: false });

    const primaryLenders = useFields('supplier');
    const moldStatus = useFields('moldStatus');


    // URLのQuery変更によって検索を再実施する
    useEffect(() => {
        setDetailData(data => ({ ...data, visible: false }));

        if (!!urlId) {
            getById(urlId)
                .then((record: InventoryDto) => {
                    setDetailData({ visible: true, data: record });
                })
                .catch(() => {
                })
        } else {
            setListData(data => ({ ...data, loading: true }))
            setSearchDto(search2Dto(search));

            get(search)
                .then((para: [number, InventoryDto[]]) => {
                    const [count, vos] = para;
                    setListData({ loading: false, total: count, data: vos })
                })
                .catch(() => {
                    setListData(data => ({ ...data, loading: false }))
                });
        }
    }, [urlId, search, refresh]);


    // 検索条件を変更する
    const setSearch = (researchDto: InventorySearchDto) => {

        // 期間範囲の編集
        if (!!researchDto.requestDate && !!researchDto.requestDate[0]) {
            researchDto.requestDate[0] = researchDto.requestDate[0].startOf('day');
        }
        if (!!researchDto.requestDate && !!researchDto.requestDate[1]) {
            researchDto.requestDate[1] = researchDto.requestDate[1].endOf('day');
        }
        if (!!researchDto.answerDeadline && !!researchDto.answerDeadline[0]) {
            researchDto.answerDeadline[0] = researchDto.answerDeadline[0].startOf('day');
        }
        if (!!researchDto.answerDeadline && !!researchDto.answerDeadline[1]) {
            researchDto.answerDeadline[1] = researchDto.answerDeadline[1].endOf('day');
        }

        // 検索条件変更を反映する
        const query = dto2search(researchDto);

        // URLを再設定する
        push(`/modelassets${query}`);
    };

    // 一覧の処理
    // レコードを削除する
    const handleDelete = (id: number, version: number) => {

        setListData({ loading: true, total: listData.total, data: listData.data })
        removeById(id, version)
            .then(() => {
                setRefresh(!refresh);
            });
    };
    const supplierOptions = useFields('supplier');
    const statusOptions = useFields('moldStatus');

    const download = (id?: number) => {

        setListData(data => ({ ...data, loading: true }));

        if((typeof id)=="number") {
            getById(`${id}`)
                .then((dto: InventoryDto) => {

                    setListData(data => ({ ...data, loading: false }));

                    let excelData = [];

                    // title
                    excelData.push([
                        // 回答状態
                        intl.formatMessage({ id: 'inventory.status' }),
                        // 有高確認依頼番号
                        intl.formatMessage({ id: 'inventory.inventoryRequestNo' }),
                        // 有高確認依頼日
                        intl.formatMessage({ id: 'inventory.requestDate' }),
                        // 回答希望日
                        intl.formatMessage({ id: 'inventory.answerDeadline' }),
                        // 一次預け先
                        intl.formatMessage({ id: 'model.primaryLender' }),
                        // 一次預け先名称
                        intl.formatMessage({ id: 'model.primaryLenderName' }),
                        // 有高確認依頼者
                        intl.formatMessage({ id: 'inventory.requester' }),
                        // 依頼日
                        intl.formatMessage({ id: 'inventory.requestDate2' }),
                        // 返信担当
                        intl.formatMessage({ id: 'inventory.responseUser' }),
                        // 返信日時
                        intl.formatMessage({ id: 'inventory.responseTime' }),
                        // 確認担当
                        intl.formatMessage({ id: 'inventory.confirmationUser' }),
                        // 確認日時
                        intl.formatMessage({ id: 'inventory.confirmationTime' }),

                        // 資産番号
                        intl.formatMessage({ id: 'model.assetNo' }),
                        // 補助番号
                        intl.formatMessage({ id: 'model.auxiliaryNo' }),
                        // 金型品番
                        intl.formatMessage({ id: 'model.moldNo' }),
                        // 最終預け先名称
                        intl.formatMessage({ id: 'model.finalLenderName' }),
                        // 面数
                        intl.formatMessage({ id: 'model.moldsNum' }),
                        // 実面数
                        intl.formatMessage({ id: 'inventory.actualMoldsNum' }),
                        // 特記事項
                        intl.formatMessage({ id: 'inventory.remarks' }),
                    ])

                    // 回答状態
                    const status = displayUtil.field(statusOptions)(dto.status);
                    // 有高確認依頼番号
                    const inventoryRequestNo = dto.inventoryRequestNo;
                    // 有高確認依頼日
                    const requestDate = displayUtil.date(dto.requestDate);
                    // 回答希望日
                    const answerDeadline = displayUtil.date(dto.answerDeadline);
                    // 一次預け先
                    const primaryLender = dto.primaryLender;
                    // 一次預け先名称
                    const primaryLenderName = displayUtil.field(supplierOptions)(dto.primaryLender);
                    
                    // 有高確認依頼者
                    const userName1 = dto.userName1;
                    // 依頼日
                    const modifiedTime = displayUtil.date(dto.modifiedTime);

                    // 返信担当
                    const userName2 = dto.userName2;
                    // 返信日時
                    const responseTime = displayUtil.locationDateTime(dto.responseTime, user?.serviceState);
                    // 返信日時（仕入先）
                    const responseTimeSupplier = displayUtil.date(dto.responseTime);

                    // 確認担当
                    const userName3 = dto.userName3;
                    // 確認日時
                    const confirmationTime = displayUtil.locationDateTime(dto.confirmationTime, user?.serviceState);
                    // 確認日時（仕入先）
                    const confirmationTimeSupplier = displayUtil.date(dto.confirmationTime);

                    // row
                    dto.details?.forEach(detail => {
                        excelData.push([
                            // 回答状態
                            status,
                            // 有高確認依頼番号
                            inventoryRequestNo,
                            // 有高確認依頼日
                            requestDate,
                            // 回答希望日
                            answerDeadline,
                            // 一次預け先
                            primaryLender,
                            // 一次預け先名称
                            primaryLenderName,
                            // 有高確認依頼者
                            userName1,
                            // 依頼日
                            modifiedTime,
                            // 返信担当
                            userName2,
                            // 返信日時
                            responseTime,
                            // 確認担当
                            userName3,
                            // 確認日時
                            confirmationTime,

                            // 資産番号
                            detail.assetNo,
                            // 補助番号
                            detail.auxiliaryNo,
                            // 金型品番
                            detail.moldNo,
                            // 最終預け先名称
                            detail.finalLenderName,
                            // 面数
                            detail.moldsNum,
                            // 実面数
                            detail.actualMoldsNum,
                            // 特記事項
                            detail.remarks,
                        ])
                    })


                    let supplierExcelData = [];

                    // title
                    supplierExcelData.push([
                        // 回答状態
                        intl.formatMessage({ id: 'inventory.status' }),
                        // 有高確認依頼番号
                        intl.formatMessage({ id: 'inventory.inventoryRequestNo' }),
                        // 有高確認依頼日
                        intl.formatMessage({ id: 'inventory.requestDate' }),
                        // 回答希望日
                        intl.formatMessage({ id: 'inventory.answerDeadline' }),
                        // 一次預け先
                        intl.formatMessage({ id: 'model.primaryLender' }),
                        // 一次預け先名称
                        intl.formatMessage({ id: 'model.primaryLenderName' }),
                        // 返信担当
                        intl.formatMessage({ id: 'inventory.responseUser' }),
                        // 返信日時
                        intl.formatMessage({ id: 'inventory.responseTime' }),
                        // 確認担当
                        intl.formatMessage({ id: 'inventory.confirmationUser' }),
                        // 確認日時
                        intl.formatMessage({ id: 'inventory.confirmationTime' }),

                        // 資産番号
                        intl.formatMessage({ id: 'model.assetNo' }),
                        // 補助番号
                        intl.formatMessage({ id: 'model.auxiliaryNo' }),
                        // 金型品番
                        intl.formatMessage({ id: 'model.moldNo' }),
                        // 最終預け先名称
                        intl.formatMessage({ id: 'model.finalLenderName' }),
                        // 面数
                        intl.formatMessage({ id: 'model.moldsNum' }),
                        // 実面数
                        intl.formatMessage({ id: 'inventory.actualMoldsNum' }),
                        // 特記事項
                        intl.formatMessage({ id: 'inventory.remarks' }),
                    ])

                    // row
                    dto.details?.forEach(detail => {
                        supplierExcelData.push([
                            // 回答状態
                            status,
                            // 有高確認依頼番号
                            inventoryRequestNo,
                            // 有高確認依頼日
                            requestDate,
                            // 回答希望日
                            answerDeadline,
                            // 一次預け先
                            primaryLender,
                            // 一次預け先名称
                            primaryLenderName,
                            // 返信担当
                            userName2,
                            // 返信日時
                            responseTimeSupplier,
                            // 確認担当
                            userName3,
                            // 確認日時
                            confirmationTimeSupplier,
                            
                            // 資産番号
                            detail.assetNo,
                            // 補助番号
                            detail.auxiliaryNo,
                            // 金型品番
                            detail.moldNo,
                            // 最終預け先名称
                            detail.finalLenderName,
                            // 面数
                            detail.moldsNum,
                            // 実面数
                            detail.actualMoldsNum,
                            // 特記事項
                            detail.remarks,
                        ])
                    })


                    let trueExcelData = !user?.isSupplier ? excelData : supplierExcelData
                    
                    const worksheet = XLSX.utils.aoa_to_sheet(trueExcelData);
                    const workbook = XLSX.utils.book_new();

                    XLSX.utils.book_append_sheet(workbook, worksheet, intl.formatMessage({ id: 'inventory.detail' }));
                    XLSX.writeFile(workbook, `${intl.formatMessage({ id: 'inventory.detail' })}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`);
                })
                .catch(() => {

                    setListData(data => ({ ...data, loading: false }));
                });
        } else {
            const downloadSearchDto = { ...searchDto, rowCount: 0 };

            get(dto2search(downloadSearchDto))
                .then((para: [number, InventoryDto[]]) => {

                    setListData(data => ({ ...data, loading: false }));
                    
                    const [, vos] = para;
                    let excelData = [];
                    // タイトル
                    excelData.push([
                        // intl.formatMessage({ id: 'forecast.buyer' }),
                        // intl.formatMessage({ id: 'forecast.buyerName' }),
                        intl.formatMessage({ id: 'inventory.status' }),
                        intl.formatMessage({ id: 'inventory.inventoryRequestNo' }),
                        intl.formatMessage({ id: 'model.primaryLender' }),
                        intl.formatMessage({ id: 'model.primaryLenderName' }),
                        intl.formatMessage({ id: 'inventory.answerDeadline' }),
                        intl.formatMessage({ id: 'inventory.requestDate' }),
                        intl.formatMessage({ id: 'inventory.modelNum' }),
                    ])
                    // 詳細データ
                    vos.forEach(vo => [
                        excelData.push([
                            // vo.buyer,
                            // displayUtil.field(buyerOptions)(vo.buyer),
                            displayUtil.field(moldStatus)(vo.status),
                            vo.inventoryRequestNo,
                            vo.primaryLender,
                            displayUtil.field(primaryLenders)(vo.primaryLender),
                            displayUtil.date(vo.answerDeadline),
                            displayUtil.date(vo.requestDate),
                            vo.modelNum
                        ])
                    ])

                    const worksheet = XLSX.utils.aoa_to_sheet(excelData);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, intl.formatMessage({ id: 'inventory.list' }));
                    XLSX.writeFile(workbook, `${intl.formatMessage({ id: 'inventory.list' })}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`);
                })
                .catch(() => { 

                setListData(data => ({ ...data, loading: false }));
            });

        }
    };



    const updateStatus = (status: string) => {

        if (!!detailData.data) {

            const data: any = detailData.data;
            data.status = status;

            mergeInventory(data)
                .then((dtos: InventoryDto) => {

                    const record = dtos;
                    setDetailData({ visible: true, data: record });
                }
                ).catch(
                    () => {
                    }
                );
        }

    }
    // 検索の処理
    const handleSearch = (value: InventorySearchDto) => {
        setSearchVisible(false)
        setSearch(value);
    };

    const handleReply = (detailDto: InventoryDetailDto[]) => {
        const inventoryDto: InventoryDto = {...detailData.data!, details: detailDto}
        return reply(inventoryDto).then(result => {
            setDetailData({visible: true, data: result})
            // setRefresh(!refresh)
        })
    }


    const reset = (rowCount: number) => {
        setSearch({

            status: [],
            // 有高確認依頼番号
            inventoryRequestNo: '',
            // 一次預け先コード
            primaryLenders: [],
            // 一次預け先名称
            primaryLenderName: '',
            // 依頼日
            requestDate: [null, null],
            // 回答希望日
            answerDeadline: [null, null],

            rowCount: rowCount

        } as InventorySearchDto)
    }

    const listProps = {
        // 画面loading状態
        loading: listData.loading,
        // 件数合計
        total: listData.total,
        // 一覧画面の表示データ
        data: listData.data,
        // 検索条件（フィルター、ページとソート順）
        searchDto: searchDto,
        // 検査画面を表示する
        openSearchModal: () => setSearchVisible(true),
        openFormModal: () => setFormVisible(true),
        //Download
        download: download,
        // 検査画面を表示する
        handleResearch: setSearch,
        handleSearch: handleSearch,
        handleDelete: handleDelete,
        reset: reset
    };

    const searchProps = {
        // 検査画面の表示状態
        visible: searchVisible,
        searchDto: searchDto,
        handleSearch: (value: InventorySearchDto) => {
            setSearchVisible(false);
            setSearch(value);
        },
        close: () => setSearchVisible(false),
    };

    //  金型詳細一览
    const detailProps = {
        // 詳細画面の表示状態
        visible: detailData.visible,
        detail: detailData.data,
        close: () => {
            if (dto2search(searchDto) !== '') {
                goBack();
            } else {
                push('/modelassets');
            }
        },
        download: download,
        updateStatus: updateStatus
    };

    const supplierDetailProps = {
        // 詳細画面の表示状態
        visible: detailData.visible,
        // visible: true ,
        detail: detailData.data,
        close: () => {
            if (dto2search(searchDto) !== '') {
                goBack();
            } else {
                push('/modelassets');
            }
        },
        refresh: () => {
            if (!!(urlId)) {
                getById(urlId)
                    .then((record: InventoryDto) => {

                        setDetailData({ visible: true, data: record });
                    })
                    .catch(() => {
                    });
            }
        },
        download: download,
        reply: handleReply,
    };

    //一次貸し出し先の金型数の取得処理
    const handleRequest = (requestDto: ModelRequestDto) => {
        setRequestData({ visible: true, data: requestDto });
        setEntryConfrimVisible(true);
        // setFormVisible(false);

        getModelNumBySupplier(requestDto)
            .then((para: ModelNumBySupplierDto[]) => {
                setResultData({ loading: true, total: 0, data: para })

            })
            .catch(() => {
                setResultData(data => ({ ...data, loading: false }))
            });

    };
    //有高確認情報Insert処理
    const handlePersist = () => {

        if (!!requestData.data) {
            persist(requestData.data)
                .then((result: any) => {
                    console.log('handleOutput-result', result);
                    history.push('/modelassets?rowCount=10')
                    setRefresh(!refresh);
                    setEntryConfrimVisible(false);
                    setFormVisible(false);
                })
                .catch(() => {
                    console.log('handleOutput-result-err');
                });
        }
    };

    //有高確認登録Props
    const entryProps = {
        visible: formVisible,
        close: () => setFormVisible(false),
        handleRequest: handleRequest,
    };

    //有高確認登録確認Props
    const entryConfrimProps = {
        visible: entryConfrimVisible,
        close: () => setEntryConfrimVisible(false),
        // openFormModal: openFormModal,
        resultData: resultData.data,
        requestData: requestData.data as ModelRequestDto,
        handlePersist: handlePersist
    };

    return (
        <>
            <List {...listProps} />
            <Search {...searchProps} />
            {!user?.isSupplier ? <Detail {...detailProps} /> : <SupplierDetial {...supplierDetailProps} />}
            {/* <SupplierDetial {...supplierDetailProps} />             */}
            <EntryProps {...entryProps} />
            <EntryConfrimProps {...entryConfrimProps} />

        </>
    )

}