// React APIs
import React, { useState, useEffect, KeyboardEvent, ChangeEvent } from 'react';
import { FormattedMessage, useIntl, } from 'react-intl';
import { Modal, Form, Select, Input, Button, Checkbox, Divider, Row, Col, Popconfirm } from 'antd';
import { DownloadOutlined } from "@ant-design/icons";

// 機能内部のAPIs
import { Field } from '../fields';
import { filterUtil, } from '../util'
import { DocCreateDto } from './api'
import './index.css';
import { Attachment, CommonExcelUpload, CommonExcelUploadProps, RowData } from '../common';
import { CommonFileUpload } from '../common/CommonFileUpload';

const { TextArea } = Input;

export interface Props {
  supplierOptions: Field[];
  categoryOptions: Field[];
  disabled: boolean;
  visible: boolean;
  selectVisible: boolean;
  setSelectVisible: () => void;
  formDataClearable: boolean;
  close: () => void;
  handleOpenCreateConfirm: (value: DocCreateDto) => void;
  downloadTemplate: () => void;
}

export function DocCreate(props: Props) {

  const [attachments, setAttachments] = useState<Attachment[]>([]);

  const [form] = Form.useForm<DocCreateDto>();
  const categoryTitle = <FormattedMessage id='docInfo.documentCategory' />;
  const supplierTitle = <FormattedMessage id='docInfo.destinationName' />;
  const remarksTitle = <FormattedMessage id='docInfo.sendContent' />;
  const uploadTitle = <FormattedMessage id='common.upload' />;
  const titleTitle = <FormattedMessage id='docInfo.title' />;
  const selectAll = <FormattedMessage id='docInfo.selectAll' />;
  const modalTitle = <FormattedMessage tagName='h4' id='docInfo.add' />;
  const confirm = <FormattedMessage id='common.confirm' />;
  const createCancel = <FormattedMessage id='common.cancel' />;
  const createConfirm = <FormattedMessage id='message.addConfirm' />;
  const intl = useIntl();
  const selectRequiredMsg = (itemTitle: any) => <FormattedMessage id='message.inputRequired' values={{ title: itemTitle, }} />

  useEffect(() => {
    setAttachments([]);
    setSelectState(false)  // 「すべて選択」ボタンをオフに設定
    form.resetFields()  // フォームデータをすべてクリア
  }, [form, props.formDataClearable])

  useEffect(() => {
    if (props.visible === false) {   // ウィンドウが非表示に設定されている場合 (ユーザーが閉じるボタンをクリックした場合)、フォーム内のデータは 300 ミリ秒後に非同期的に消去されます
      setAttachments([]);
      setSelectState(false)  // 「すべて選択」ボタンをオフに設定
      form.resetFields()  // フォームデータをすべてクリア
    }
  }, [form, props.visible])

  const handleSubmit = (docCreateDto: DocCreateDto) => {
    docCreateDto.attachments = attachments;
    props.handleOpenCreateConfirm(docCreateDto);
  };


  const setUploadSuppliersListToSelect = (newSuppliersList: string[]) => {
    const selectedValue = form.getFieldValue('supplier')  // アップロード前に選択されているアイテムを取得する

      let newSelect: string[] = newSuppliersList
      if ( !!selectedValue && selectedValue.length > 0) {
         newSelect = Array.from(new Set([...newSuppliersList, ...selectedValue]))  // 選択したアイテムを結合し、アップロードされたアイテムをファイルします
      }
      form.setFieldsValue({ supplier: newSelect }) //選択した項目を設定する

      if (newSelect.length < props.supplierOptions.length) {  // 新しい選択が合計よりも少ない場合は、すべて選択チェックボックスをオフに設定し、それ以外の場合はオンに設定します
        setSelectState(false)
      } else {
        setSelectState(true)
      }

      return newSelect
  }

  const validate = (rowDatas: RowData[]) => {
    const suppliers = props.supplierOptions
    const suppliersList: string[] = suppliers.map( supplier => supplier.value )
    const rowDatasList: string[] = rowDatas.map( rowData => `${rowData.destinationName}` )
    const errors: string[] = []

    if(!!suppliersList && !!rowDatasList && suppliersList.length > 0 && rowDatasList.length > 0) {  
      const tempRowDatasList = [...rowDatasList]  // まず、浅いコピーを作成して、重複検出に使用でき、その要素がいつでも削除できる配列を生成します。
      const newRowDatasList = rowDatasList.filter( (rowData, index) => {  // 重複フィールドを検出してエラー リストに保存し、最初の送信が正当であるかどうかを確認し、最初に正当な送信を取得するようにフィルタリングします
        if(tempRowDatasList.length > index + 1) {
          tempRowDatasList[index] = ''
          tempRowDatasList.forEach( (tempRowData, tempIndex) => {
            if(tempRowData === rowData) {
              tempRowDatasList[tempIndex] = ''
              errors.push(intl.formatMessage({id: 'message.excelUploadDuplicatedError'}, {i: index + 1, j: tempIndex + 1}))
            }
          })
        }
        
        const checkRowDataInSupplierResult = suppliersList.includes(rowData)
        if(!checkRowDataInSupplierResult) {
          errors.push(intl.formatMessage({id: 'docInfo.destinationNotExist'}, {i: index + 1, name: rowData}))
          
        }
        return checkRowDataInSupplierResult
      })



      setUploadSuppliersListToSelect(newRowDatasList)

    } else {
      errors.push(intl.formatMessage({id: 'message.uploadedFileEmpty'}))
    }
    if (errors.length > 0) {
      throw errors;
    }
    return rowDatas
  };

  const uploadProps: CommonExcelUploadProps = {
    row: {
      columns: [
        // 送信先リスト
        { key: 'docInfo.destinationList', field: 'destinationName', type: 'string', isRequired: true, validators: [], },
      ],
      uniqueItems: [],
    },
    validate: validate,
    upload: (datas: RowData[]) => {

      const selectFromUpload: string[] = datas.map((data) => `${data.destinationName}`) // ファイルのアップロードに関するアイデアを得る
      setUploadSuppliersListToSelect(selectFromUpload)

    },
  };

  /**
   * @param event ユーザーがキーボードを押すイベント
   * ユーザーがキーボードの Enter で改行文字を入力できないようにします (Enter キーのデフォルトの動作を防止します)
   */
  const handleDisableEnterKeyDown = (event?: KeyboardEvent<HTMLTextAreaElement>) => {
    try {
      if (event?.key === 'Enter') {
        event.preventDefault();
      }
    } catch (error) {
      console.error('ERROR', 'Can not disable user entered "Enter(/n)" key in DocCreate.tsx -> handleDisableEnterKeyDown')
    }
  }

  /**
   * @param evnet ユーザー onChange イベント
   * 
   */
  const handleCheckUserInputAndClearEnterCharacter = (evnet?: ChangeEvent<HTMLTextAreaElement>) => {
    try {
      form.setFieldsValue({ title: evnet?.target?.value.replace(/[\r\n]/g, "") })
    } catch (error) {
      console.error('ERROR', 'Can not clear user typed "Enter(/n)" character in DocCreate.tsx -> handleCheckUserInputAndClearEnterCharacter')
    }
  }

  // ラジオボタンの状態
  const [selectState, setSelectState] = useState(false)

  const width = "1024px"
  return (
    <Modal
      width={width}
      title={modalTitle}
      footer={null}
      visible={props.visible}
      closable={false}
      afterClose={props.close}
      destroyOnClose={true}
      zIndex={700}>
      <Form
        className="form"
        form={form}
        preserve={false}
        onFinish={handleSubmit}
        layout={'horizontal'}
        colon={false}
        requiredMark={true}
        size={'large'}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 15 }}
      >
        <Row>
          <Col span={13}>
            
            <Row>
              <Col span={24}>
                <Form.Item name='supplier' label={supplierTitle}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'docInfo.supplier' }) })
                    },
                  ]}
                >
                  <Select
                    allowClear // クリアボタンを追加
                    placeholder={selectRequiredMsg(supplierTitle)} // デフォルトでテキストを表示する
                    options={props.supplierOptions}   // オプション データ ソース
                    mode={'multiple'}  // 選択モード。詳細については、Ant d の選択コンポーネントを参照してください
                    maxTagCount={5}  // 選択ボックスに表示できる最大数 (追加の数値オプションがいくつあるかを尋ねる最後のプロンプトを除く)
                    listHeight={290}  // ドロップダウンリストの長さ
                    open={props.selectVisible} // ドロップダウンの開いた状態
                    showArrow={true} // ドロップダウン矢印を表示するかどうか
                    size={'large'} // ボックスのサイズを選択してください
                    filterOption={filterUtil.selectFilter}  // フィルタードロップダウン条件
                    onChange={ // このイベントは、選択した項目が変更されたときに呼び出されます。選択した項目の数が項目の合計数より少ない場合は、「すべて選択」チェックボックスを選択解除に変更し、それ以外の場合は選択に変更
                      () => {
                        if (form.getFieldValue('supplier').length < props.supplierOptions.length) {
                          setSelectState(false)
                        } else {
                          setSelectState(true)
                        }
                      }
                    }
                    getPopupContainer={  // 親ノードに続いてスクロールするようにドロップダウンを設定します
                      triggerNode => {
                        return triggerNode.parentNode || document.body;
                      }
                    }
                    dropdownRender={allSelectValue => (  // すべて選択ボタン
                      <div>
                        <div style={{ padding: '4px 8px 8px 8px', cursor: 'pointer' }}>
                          <Checkbox checked={selectState} onChange={(e) => {
                            // 選択するかどうかを決定する
                            if (e.target.checked === true) {
                              setSelectState(true) // チェックを入れるとチェックの状態を変更します
                              // 選択すると、すべてのリスト値を functionIds に割り当てます
                              form.setFieldsValue({
                                supplier: props.supplierOptions.map((item) => item.value) // 選択した場合、選択するデータ内のすべての値を割り当てます。
                              })
                            } else {
                              setSelectState(false)
                              form.setFieldsValue({
                                supplier: [] // すべての選択を解除すると、すべての選択の選択状態が解除されます。
                              })
                            }
                          }}>{selectAll}</Checkbox>
                          {/* <div onClick={props.setSelectVisible}>close</div> */}
                        </div>
                        <Divider style={{ margin: '0' }} />
                        {/* Option タグ値 */}
                        {allSelectValue}
                      </div>
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row style={{marginTop: '340px'}}>
              <Col span={24}>
                <div style={{ display: 'inline-block' }}>
                    <Button onClick={props.downloadTemplate} style={{ margin: '0 0 0 10px', marginRight: 10 }}>
                        <DownloadOutlined />
                        <FormattedMessage id='supplierEstimates.templateDownload' />
                    </Button>
                </div>
              </Col>
            </Row>

            <Row style={{marginTop: '20px'}}>
              <Col span={7} style={{textAlign: 'right', paddingRight: '10px'}}>
                {intl.formatMessage({id: 'docInfo.uploadDestination'})}
              </Col>
              <Col span={15} >
                <div className='docDestinationNameUpload'>
                  <CommonExcelUpload {...uploadProps} />
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={11}>
            <Form.Item name='documentCategory' label={categoryTitle}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'docInfo.documentCategory' }) }),
                },
              ]}>
              <Select placeholder={selectRequiredMsg(categoryTitle)}
                options={props.categoryOptions} />
            </Form.Item>
            <Form.Item name='title' label={titleTitle}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'docInfo.title' }) }),
                },
              ]}>
              <TextArea showCount autoSize allowClear maxLength={120} onKeyDown={handleDisableEnterKeyDown} onChange={handleCheckUserInputAndClearEnterCharacter} />
            </Form.Item>
            <Form.Item
              name='message'
              label={remarksTitle}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'estimate.nullError' }, { name: intl.formatMessage({ id: 'docInfo.sendContent' }) })
                },
              ]}>
              <TextArea
                // rows={8} 
                maxLength={1000}
                showCount
                autoSize={{ minRows: 11 }}
              />
            </Form.Item>
            <Form.Item name='files' label={uploadTitle}>
              <Form.Item name='attachments' valuePropName='fileList' noStyle>
                <CommonFileUpload
                  attachments={[]}
                  canUpload={true}
                  persist={(uuid: string) => {
                    setAttachments([...attachments, { id: -1, uuid: uuid }]);
                  }}
                  remove={(uuid: string) => {
                    setAttachments(attachments.filter((attachment: Attachment) => attachment.uuid !== uuid))
                  }}
                />
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={5} offset={16}>
            <Row>
              <Col span={5} offset={7}>
                <Button onClick={props.close}>
                  <FormattedMessage id='common.close' />
                </Button>
              </Col>
              <Col span={5} offset={3}>


                <Popconfirm
                  title={createConfirm}
                  onConfirm={() => {
                    form.submit()
                  }}
                  okText={confirm}
                  cancelText={createCancel}
                >
                  <Button
                    className='submitBtn'
                    htmlType='submit'
                    disabled={props.disabled}
                  >
                    <FormattedMessage id='common.add' />
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
